

import React from 'react'
import One from '../../../../../../../assets/dice/one.png'
import Two from '../../../../../../../assets/dice/two.png'
import Three from '../../../../../../../assets/dice/three.png'
import Four from '../../../../../../../assets/dice/four.png'
import Five from '../../../../../../../assets/dice/five.png'
import Six from '../../../../../../../assets/dice/six.png'


const dices = {
    1: {
      eyes: 1,
      image: One
    }, 
    2: {
      eyes: 2,
      image: Two
    },
    3: {
      eyes: 3,
      image: Three
    },
    4: {
      eyes: 4,
      image: Four
    },
    5: {
      eyes: 5,
      image: Five
    },
    6: {
      eyes: 6,
      image: Six
    }
}



function Dices(props) {
  return (
    <div className='h-3/5 flex flex-row justify-center gap-10 md:gap-20 items-center'>
    <div>
      <img src={dices[props.diceOne].image} alt="None" className='w-60' />
    </div>
    <div>
      <img src={dices[props.diceTwo].image} alt="None" className='w-60' />
    </div>
  </div>
  )
}

export default Dices