import React from "react"


function Content({img, flexRow, header, info}) {
    return (
      <div className={`flex h-max ${flexRow} justify-between`}>
          <div className='hidden w-1/2 md:flex justify-center items-center ml-2'>
              <img src={img} alt="None" className='w-1/1 rounded-2xl shadow-lg'/>
          </div>
          <div className='w-1/1 md:w-1/2 h-full flex justify-center items-center'>
              <div className='m-20 text-xl flex flex-col items-center'>
                  <div className='text-3xl font-bold'>{header}</div>
                  <br></br>
                  <div className='text-2xl'>
                  {info}
                  </div>
              </div>
          </div>
      </div>
    )
  }
  
  export default Content