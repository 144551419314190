import React from 'react'
import GameHeader from '../../../../../../components/ingame/gameheader'
import Dices from './maingamecomponents/dices'
import Alternatives from '../../../../../../components/ingame/alternatives'


function Game(props) {
  return (
    <div className='h-full'>
        <GameHeader title="Hvor mange prikker viser terningene?"/>
        <Dices diceOne={props.dice1} diceTwo={props.dice2} />
        <Alternatives rightAnswer={props.rightAnswer} taskNumber={props.taskNumber} alternativeArr={props.alternativeArr} />
  </div>
  )
}

export default Game