
import img1 from '../../../../assets/homepagepics/school1.jpeg'
import img2 from '../../../../assets/homepagepics/school2.jpeg'
import img3 from '../../../../assets/homepagepics/school3.jpeg'

export const contentArr = [
     {
        img: img1,
        header: "Hva er Doctuz?",
        flex: "flex-row",
        info: "Doctuz er mer enn bare en matematikkundervisningsside. Det er et fellesskap av lærelystne individer som deler en lidenskap for tall og logikk. Vår visjon er å gjøre matematikkundervisning til en spennende reise som fremmer livslang læring og en dyp forståelse av faget. Velkommen til Doctuz, der matte blir magi!"
    },
    {
        img: img2,
        header: "Hvordan få tilgang?",
        flex: "flex-row-reverse",
        info: 'For å få tilgang til læringsplattformen "Doctuz", oppretter du en konto ved å registrere deg med din e-postadresse og et passord. Deretter får du tilgang til plattformen ved å logge inn med den opprettede kontoen. Utforsk kurs, leksjoner og oppgaver, og delta i fellesskapet for en inspirerende matematikkopplevelse som fremmer nysgjerrighet og mestring.'
    },
    {
        img: img3,
        header: "Hvem har laget Doctuz?",
        flex: "flex-row",
        info: 'Skaperne av "Doctuz" er et lidenskapelig team av matematikere, pedagoger og teknologientusiaster. Deres felles mål var å forme en revolusjonerende læringsplattform som gjør matematikk spennende og tilgjengelig for alle. Med sin ekspertise har de kombinert interaktive leksjoner, kreative oppgaver og en dynamisk læringsmetodikk for å inspirere elevenes nysgjerrighet. Deres engasjement for å tilby en positiv læringsopplevelse har ført til utviklingen av "Doctuz" - en plattform som ikke bare lærer elever matematikk, men også vekker deres lidenskap og glede for faget.'
    },

]

