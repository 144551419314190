
import React from 'react'
import Content from './descriptions/content'
import { contentArr } from './descriptions/contentobj'

function Description() {
  return (
    <div>
      {contentArr.map((obj) => {
        return(
        <div className='w-full h-max '>
          <Content header={obj.header} img={obj.img} flexRow={obj.flex} info={obj.info} />
      </div>)
      })}
    </div>
  )
}

export default Description