import React from 'react'
import Score from '../../../../../components/ingame/scorebar'
import { useSelector } from 'react-redux'
import PreGameSchreen from '../../../../../components/ingame/preGameSchreen'
import Games from './components/subitizingGames'



function SubitizingFirstgrade() {

const showIntro = useSelector((state) => state.tasks.ui.showIntro)


  return (
    <div className='bg-gradient-to-br from-blue-700 via-cyan-100 to-blue-500 bg-fixed'>
      <div className='h-screen flex flex-row'>
        <Score />
        {showIntro ? <PreGameSchreen /> : null}
        {(!showIntro) ? <Games /> : null}
      </div>
    </div>
  )
}

export default SubitizingFirstgrade